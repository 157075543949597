body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
}
p, h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}
.bg{
    background-image: url("./components/img/bg.png");
    height: calc(100vh - 60px);
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.img-wrapper{
    width: 462px;
    height: 384px;
}
.logo{
    width: 100%;
    display: none;
}
.logo-small{
    width: 100%;
    display: block;
}
.footer{
    background: #E87671;
    height: 60px;
    color: #0F1011;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 8px;
}
.terms-wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 24px;
}
.button{
    width: 180px;
    height: 52px;
}
a{
    color: #0F1011;
}
@media (min-width: 320px){
    .bg{
        height: calc(100vh - 89px);
        gap: 79px;
    }
    .img-wrapper{
        width: 170px;
        height: 188px;
    }
    .footer div:nth-child(2) a {
        text-decoration: none;
    }
    .footer{
        height: 89px;
        font-size: 12px;
        flex-direction: column;
    }
    .button{
        width: 180px;
        height: 52px;
    }
}
@media (min-width: 390px){
    .img-wrapper{
        width: 306px;
        height: 323px;
    }
}
@media (min-width: 744px){
    .bg{
        height: calc(100vh - 60px);
    }
    .img-wrapper{
        width: 462px;
        height: 388px;
    }
    .logo-small{
        display: none;
    }
    .logo{
        display: block;
    }
    /*.footer div:nth-child(1) {*/
    /*    order: 0;*/
    /*}*/
    /*.footer div:nth-child(3) {*/
    /*    order: 3;*/
    /*}*/
    .footer div:nth-child(2) {
        /*order: 2;*/
        padding-left: 60px;
    }
    .footer{
        flex-direction: row;
        height: 60px;
        font-size: 12px;
        justify-content: space-between;
        padding: 0 30px;
    }
}
@media (min-width: 1440px){
    .bg{
        gap: 49px;
    }
    .footer{
        padding: 0 71px;
    }
}

@media (min-width: 1700px){
    .footer{
        padding: 0 71px;
    }
}